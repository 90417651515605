<div class="h-100">
  <div class="donant-main">
    <div class="donant-picture"></div>
    <div class="donant-form d-flex flex-column justify-center h-100">
      <div class="h-25">
        <v-img class="logo-wide" src="@/assets/images/logo_wide_2025.png" width="200"/>        
      </div>
      <div class="d-flex flex-column justify-center align-center h-50">
        <div class="px-5 w-100">
          <div class="text-center text-h5">¡Hola!</div>
          <div class="text-center">Inicia sesión para continuar administrar donaciones.</div>
          <div class="mt-3">
            <v-text-field v-model="userdata.email" label="Correo electrónico"
            :error-messages="v$.userdata.email.$errors.map(e => e.$message)" />
            <v-text-field v-model="userdata.password" :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" label="Contraseña" @click:append-inner="show = !show"
            :error-messages="v$.userdata.password.$errors.map(e => e.$message)" />
          </div>
          <div class="mt-3 W-100">
            <v-btn class="w-25" variant="flat" size="large" :to="{name: 'home'}">volver</v-btn>
            <v-btn class="w-75" color="primary" variant="flat" size="large" :loading="isloading" @click="signin()">iniciar sesión</v-btn>
          </div>
          <div class="mt-3">
            <v-alert v-if="iserror" color="error" icon="$error" variant="tonal" title="Error" :text="iserror"></v-alert>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-end h-25 text-caption">
        &#169; Derechos Reservados UWC 2024
      </div>
    </div>
  </div>
</div>