<v-app-bar color="primary" :elevation="2">
  <template v-slot:prepend>
    <v-img class="ml-2" src="@/assets/images/logo_white.png" width="200" />
  </template>
  <v-app-bar-title>PLATAFORMA DONANTES</v-app-bar-title>
  <v-spacer></v-spacer>
  <router-link :to="{name:'managerdonants'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="donantes"  activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-account-group"></v-icon>
    </v-btn>
  </router-link>

  <router-link :to="{name:'managerusers'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="usuarios"  activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-account"></v-icon>
    </v-btn>
  </router-link>
  
  <router-link :to="{name:'managerlanding'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="campañas"  activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-bullhorn-outline"></v-icon>
    </v-btn>
  </router-link>
  
  <router-link :to="{name:'managertemplates'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="plantillas"  activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-file-pdf-box"></v-icon>
    </v-btn>
  </router-link>
  
  <router-link :to="{name:'managerimportexport'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="datos" activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-swap-vertical-bold"></v-icon>
    </v-btn>
  </router-link>
  
  <router-link :to="{name:'managerrecurrences'}" active-class="v-btn--variant-tonal">
    <v-btn class="text-white" icon>
      <v-tooltip text="recurrencias"  activator="parent" location="bottom"></v-tooltip>
      <v-icon icon="mdi-credit-card-clock"></v-icon>
    </v-btn>
  </router-link>
  <v-spacer></v-spacer>
  <v-spacer></v-spacer>
  <template v-slot:append>
    <v-btn class="text-white" size="large" icon @click="signout()">
      <v-tooltip text="salir"  activator="parent" location="bottom"></v-tooltip>
      <v-icon>mdi-exit-to-app</v-icon>
    </v-btn>
  </template>
  <v-dialog v-model="isprocess" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item prepend-icon="mdi-update" title="Por favor espera...">
        <template v-slot:prepend>
          <div class="pe-4">
            <v-icon color="primary" size="x-large"></v-icon>
          </div>
        </template>
        <template v-slot:append>
          <v-progress-circular color="primary" indeterminate="disable-shrink" size="16" width="2"></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>    
  </v-dialog>
</v-app-bar>